<template>
  <div class="page">
    <div class="top">
      <el-form :inline="true" :model="queryParams"  class="demo-form-inline">
        <el-form-item label="月份" style="margin-right: 30px">
          <el-date-picker
            v-model="queryParams.date"
            type="month"
            :picker-options="setDisabled"
            value-format="yyyy-MM"
            style="width: 200px"
            placeholder="请选择月份">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="机构">
          <el-select v-model="queryParams.organizationName" placeholder="请选择机构">
            <el-option v-for="(item, index) in organizationNameList" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="search" @click="search">搜索</el-button>
          <el-button type="primary" class="search" plain @click="resetMethod">重置</el-button>
        </el-form-item>
        <el-button type="primary" class="search" style="float: right" @click="exportExl">导出</el-button>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      v-loading="loading"
      :header-cell-style="headerCellStyle"
      style="width: 100%">
      <template slot="empty">
        <IsEmpty />
      </template>
      <el-table-column
        type="index"
        align="center"
        width="80"
        :index="indexAdd"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="month"
        align="center"
        label="月份">
      </el-table-column>
      <el-table-column
        prop="organizationName"
        align="center"
        width="180"
        label="机构名称">
      </el-table-column>
      <el-table-column
        prop="submissionTime"
        align="center"
        label="提交时间">
      </el-table-column>
      <el-table-column
        prop="hospitalNum"
        width="200"
        align="center"
        label="当月在院人数（人）">
        <template slot="header">
          {{'当月在院人数（人）'}}
          <el-tooltip
            class="item"
            effect="dark"
            content="含不享受补贴的人数"
            placement="top"
          >
            <i class="el-icon-question" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        align="center"
        label="当月享受补贴人数（人）">
        <el-table-column
          prop="countTotal"
          align="center"
          label="合计">
        </el-table-column>
        <el-table-column
          prop="seniorsDistrictNum"
          align="center"
          label="本区老人">
        </el-table-column>
        <el-table-column
          prop="seniorsDisDistrictNum"
          align="center"
          label="非本区老人">
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="address"
        align="center"
        label="享受补贴人数当月比上月（人）">
        <el-table-column
          prop="lastMonthAdd"
          align="center"
          label="增加">
        </el-table-column>
        <el-table-column
          prop="lastMonthReduce"
          align="center"
          label="减少">
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="subsidies"
        align="center"
        label="当月应享受服务补贴（元）">
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="total > 0"
      style="margin:20px 0"
      background
      layout="total, sizes, prev, pager, next"
      :total="total"
      :page-size="queryParams.pageSize"
      :current-page="queryParams.pageNum"
      :page-sizes="[10, 20, 30, 40]"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
  </div>
</template>

<script>
  import { elderlyStatisticsSummary, getOrganizationListWithSubsidy, exportElderlyStatistics } from "@/api/institutionalSubsidies"
  import { downloadStream } from '@/utils/index'
  export default {
    name: "elderlyStatistics",
    data() {
      return {
        setDisabled: {
          disabledDate(time) {
            return time.getTime() > Date.now();  // 可选历史天、可选当前天、不可选未来天
          },
        },
        queryParams: {
          date: '',
          pageSize: 10,
          pageNum: 1,
          organizationName: ''
        },
        total: 10,
        loading: false,
        organizationNameList: [],
        tableData: []
      }
    },
    mounted() {
      this.getNowTime()
      this.getOrganizationListData()
      this.getList()
    },
    methods: {
      /*搜索*/
      search() {
        this.queryParams.pageNum = 1
        this.getList()
      },
      /*重置*/
      resetMethod() {
        this.queryParams = {
           date: '',
            pageSize: 10,
            pageNum: 1,
            organizationName: ''
        }
        this.getNowTime()
        this.getList()
      },
      /*导出*/
      exportExl() {
        this.exportElderlyStatisticsData()
      },
      // 翻页
      handleCurrentChange(val) {
        this.loading = true
        this.queryParams.pageNum = val
        this.getList()
      },
      handleSizeChange(val) {
        this.loading = true
        this.queryParams.pageSize = val
        this.getList()
      },
      /*默认当前月份*/
      getNowTime() {
        let now = new Date();
        let year = now.getFullYear(); //得到年份
        let month = now.getMonth(); //得到月份
        month = month + 1;
        month = month.toString().padStart(2, "0");
        let defaultDate = `${year}-${month}`;
        this.$set(this.queryParams, "date", defaultDate);
      },
      /*序号*/
      indexAdd(index) {
        return index + 1 + (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      },
      async getList() {
        this.loading = true
        try {
          const res = await elderlyStatisticsSummary(this.queryParams)
          this.tableData = res.data.rows
          this.total = res.data.total
          this.loading = false
        }catch (e) {
          this.loading = false
        }

      },
      async getOrganizationListData() {
        const res = await getOrganizationListWithSubsidy()
        this.organizationNameList = res.data
      },
      async exportElderlyStatisticsData() {
        const res = await exportElderlyStatistics(this.queryParams)
        downloadStream(res, `老人统计月汇总表.xls`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    background-color: #ffffff;
    height: calc(100vh - 145px);
    padding: 30px;
    box-sizing: border-box;
  }
</style>
